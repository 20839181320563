
import { defineComponent, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';

import { PartialWork } from '@/interfaces/Work';
import { formatLocalTime } from '@/utils/format-time';

import {
  getWork
} from '@/services/api';
import { getStatusTagType } from '@/utils/render';

export default defineComponent({
  setup() {
    const work = ref<PartialWork>({});
    const workId = useRoute().params.id as string;

    const fetchWorks = async() => {
      const { data } = await getWork({ workId });
      work.value = data;
      console.log('test:', data.name);
      // console.log('test:', work.value.name['zh-TW']);
    };

    onMounted(() => {
      fetchWorks();
    });

    return {
      work,
      formatLocalTime,
      getStatusTagType
    };
  }
});
