import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-23992004"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["src"]
const _hoisted_3 = ["src"]
const _hoisted_4 = ["src"]
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_descriptions_item = _resolveComponent("el-descriptions-item")!
  const _component_el_image = _resolveComponent("el-image")!
  const _component_el_tag = _resolveComponent("el-tag")!
  const _component_el_descriptions = _resolveComponent("el-descriptions")!
  const _component_el_main = _resolveComponent("el-main")!

  return (_openBlock(), _createBlock(_component_el_main, null, {
    default: _withCtx(() => [
      _createVNode(_component_el_descriptions, {
        title: "影片詳情",
        direction: "vertical",
        column: 1,
        border: ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_descriptions_item, { label: "番號" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.work?.number), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_descriptions_item, { label: "主類型" }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.work?.classifications, (classification, index) => {
                return (_openBlock(), _createElementBlock("span", { key: index }, [
                  _createTextVNode(_toDisplayString(classification.name) + " ", 1),
                  (index !== (_ctx.work?.classifications.length - 1))
                    ? (_openBlock(), _createElementBlock("span", _hoisted_1, "、"))
                    : _createCommentVNode("", true)
                ]))
              }), 128))
            ]),
            _: 1
          }),
          _createVNode(_component_el_descriptions_item, { label: "代理商" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.work?.agent?.name ? _ctx.work?.agent?.name : '-'), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_descriptions_item, { label: "影片名稱" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.work?.name?.['zh-TW']), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_descriptions_item, { label: "影片名稱_英文" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.work?.name?.['en-US']), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_descriptions_item, {
            label: "無標圖片(黃)",
            span: 2
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_image, {
                style: {"width":"300px"},
                src: _ctx.work?.coverPhoto?.path
              }, null, 8, ["src"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_descriptions_item, {
            label: "有標圖片(黃)",
            span: 2
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_image, {
                style: {"width":"300px"},
                src: _ctx.work?.captionCoverPhoto?.path
              }, null, 8, ["src"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_descriptions_item, {
            label: "無標圖片(綠)",
            span: 2
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_image, {
                style: {"width":"300px"},
                src: _ctx.work?.noExposeCoverPhoto?.path
              }, null, 8, ["src"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_descriptions_item, {
            label: "有標圖片(綠)",
            span: 2
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_image, {
                style: {"width":"300px"},
                src: _ctx.work?.noExposeCaptionCoverPhoto?.path
              }, null, 8, ["src"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_descriptions_item, {
            label: "標題縮圖",
            span: 2
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_image, {
                style: {"width":"300px"},
                src: _ctx.work?.titlePhoto?.path
              }, null, 8, ["src"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_descriptions_item, { label: "影片" }, {
            default: _withCtx(() => [
              _createElementVNode("video", {
                width: "300",
                src: _ctx.work?.video?.videoSource,
                controls: ""
              }, null, 8, _hoisted_2)
            ]),
            _: 1
          }),
          _createVNode(_component_el_descriptions_item, { label: "影片觀看數" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.work?.video?.views), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_descriptions_item, { label: "預覽片" }, {
            default: _withCtx(() => [
              _createElementVNode("video", {
                width: "300",
                src: _ctx.work?.trailerSource,
                controls: ""
              }, null, 8, _hoisted_3)
            ]),
            _: 1
          }),
          _createVNode(_component_el_descriptions_item, { label: "預覽片觀看數" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.work?.video?.trailerViews), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_descriptions_item, { label: "綠色預覽片" }, {
            default: _withCtx(() => [
              _createElementVNode("video", {
                width: "300",
                src: _ctx.work?.noExposeTrailerSource,
                controls: ""
              }, null, 8, _hoisted_4)
            ]),
            _: 1
          }),
          _createVNode(_component_el_descriptions_item, { label: "麻豆獨家" }, {
            default: _withCtx(() => [
              (_ctx.work.isExclusive)
                ? (_openBlock(), _createElementBlock("span", _hoisted_5, " 是 "))
                : (_openBlock(), _createElementBlock("span", _hoisted_6, " 否 "))
            ]),
            _: 1
          }),
          _createVNode(_component_el_descriptions_item, { label: "影片類型" }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.work?.genres, (genre, index) => {
                return (_openBlock(), _createElementBlock("span", { key: index }, [
                  _createTextVNode(_toDisplayString(genre.name) + " ", 1),
                  (index !== (_ctx.work?.genres.length - 1))
                    ? (_openBlock(), _createElementBlock("span", _hoisted_7, "、"))
                    : _createCommentVNode("", true)
                ]))
              }), 128))
            ]),
            _: 1
          }),
          _createVNode(_component_el_descriptions_item, { label: "演員" }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.work?.actors, (actor, index) => {
                return (_openBlock(), _createElementBlock("span", { key: index }, [
                  _createTextVNode(_toDisplayString(actor.name) + " ", 1),
                  (index !== (_ctx.work?.actors.length - 1))
                    ? (_openBlock(), _createElementBlock("span", _hoisted_8, "、"))
                    : _createCommentVNode("", true)
                ]))
              }), 128))
            ]),
            _: 1
          }),
          _createVNode(_component_el_descriptions_item, { label: "導演" }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.work?.directors, (director, index) => {
                return (_openBlock(), _createElementBlock("span", { key: index }, [
                  _createTextVNode(_toDisplayString(director.name) + " ", 1),
                  (index !== (_ctx.work?.directors.length - 1))
                    ? (_openBlock(), _createElementBlock("span", _hoisted_9, "、"))
                    : _createCommentVNode("", true)
                ]))
              }), 128))
            ]),
            _: 1
          }),
          _createVNode(_component_el_descriptions_item, { label: "標籤" }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.work?.tags, (tag, index) => {
                return (_openBlock(), _createElementBlock("span", { key: index }, [
                  _createTextVNode(_toDisplayString(tag.name) + " ", 1),
                  (index !== (_ctx.work?.tags.length - 1))
                    ? (_openBlock(), _createElementBlock("span", _hoisted_10, "、"))
                    : _createCommentVNode("", true)
                ]))
              }), 128))
            ]),
            _: 1
          }),
          _createVNode(_component_el_descriptions_item, { label: "大綱" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.work?.introduction?.['zh-TW']), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_descriptions_item, { label: "大綱_英文" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.work?.introduction?.['en-US']), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_descriptions_item, { label: "更新時間" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.formatLocalTime(_ctx.work?.updatedAt)), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_descriptions_item, { label: "建立時間" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.formatLocalTime(_ctx.work?.createdAt)), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_descriptions_item, { label: "發佈時間" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.formatLocalTime(_ctx.work?.publishedAt)), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_descriptions_item, { label: "到期時間" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.work?.copyrightExpiredAt ? _ctx.formatLocalTime(_ctx.work?.copyrightExpiredAt) : '-'), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_descriptions_item, { label: "評分分數（隨機／真實／評價人數）" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(`(${_ctx.work.randomEvaluation}%／${_ctx.work.realEvaluation ? _ctx.work.realEvaluation + '%' : '-'}／${_ctx.work.evaluationCount})`), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_descriptions_item, { label: "狀態" }, {
            default: _withCtx(() => [
              _createVNode(_component_el_tag, {
                type: _ctx.getStatusTagType(_ctx.work.status)
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t(`workStatus.${_ctx.work?.status}`)), 1)
                ]),
                _: 1
              }, 8, ["type"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}